var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: { color: _vm.color, loading: _vm.loading, small: "" },
      on: { click: _vm.contactedCustomer }
    },
    [_vm._v("\n  Kontaktet nå\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }