<template>
  <v-btn :color="color" :loading="loading" small @click="contactedCustomer">
    Kontaktet nå
  </v-btn>
</template>

<script>
export default {
  name: 'ContactedCustomer',
  props: {
    customerUID: {
      type: String,
      default: '',
    },
    propNumberOfTimesContactedToday: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      color: 'primary',
      loading: false,
      numberOfTimesContactedToday: 0,
      timer: 0,
    };
  },
  watch: {
    propNumberOfTimesContactedToday(val) {
      this.numberOfTimesContactedToday = val;
    },
  },
  async mounted() {
    try {
      const res = await this.$axios.get('users/' + this.customerUID);
      this.numberOfTimesContactedToday = res.data.numberOfTimesContactedToday || 0;
    }
    catch (e) {
      console.log(e);
    }
  },
  methods: {
    async contactedCustomer() {
      clearInterval(this.timer);
      this.loading = true;
      try {
        await this.$axios.patch('/users/incrementNumberOfTimesContactedToday/' + this.customerUID);
        this.numberOfTimesContactedToday++;
        this.color = 'success';
        this.timer = setTimeout(() => {
          this.color = 'primary';
        }, 5000);
        this.$emit('contacted');
      }
      catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>
